
import './App.css';
import { LandingPage } from './LandingPage';


function App() {
  
  return (

    <div className=" ">
        <LandingPage/>
    </div>
  );
}

export default App;
